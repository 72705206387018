import React from "react";
import "./forms.css"
const Form=()=>{
return(
    <div className="frm" >
<div className="txtbx"> 
    Registration is now closed.
</div>
    </div>
)
}

export default Form;